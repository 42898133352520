<template>
  <el-row>
    <el-col :span="2">
      <div class="left-menu">
        <el-image style="height: 35px" :src="require(`@/assets/lingyanspace.svg`)" />
        <span>灵燕空间</span>
      </div>
    </el-col>
    <el-col :span="18">
      <div class="centerTitleMenu">
        <ul>
          <li @click="navigateTo('默认首页')">首页总览</li>
          <li @click="navigateTo('Cert')">证书服务</li>
          <li @click="navigateTo('Course')">课堂服务</li>
          <li @click="navigateTo('System')">系统管理</li>
          <li @click="navigateTo('个人中心')">个人中心</li>
          <a
            href="https://space.bilibili.com/237905456"
            target="_blank"
            class="video-link"
            >视频教程</a
          >
          <a
            href="https://book.lingyanspace.com/books/854d6"
            target="_blank"
            class="video-link"
            >图文教程</a
          >
          <a
            href="https://gitee.com/www-lingyanspace-com"
            target="_blank"
            class="video-link"
            >开源仓库</a
          >
        </ul>
      </div>
    </el-col>
    <el-col :span="4">
      <div class="right-menu">
        <el-dropdown :hide-on-click="false" style="margin-right: 10px">
          <span
            class="el-dropdown-link"
            style="display: flex; align-items: center; justify-content: center"
          >
            <el-avatar
              :size="35"
              style="margin-right: 10px"
              :src="user.userHeaderImg"
              shape="circle"
              >未</el-avatar
            >
            <template
              v-if="
                user.userPhone !== null &&
                user.userPhone !== undefined &&
                user.userPhone !== ''
              "
            >
              <span>{{ user.userNickName }}</span>
            </template>
            <template v-else>
              <span>未登录 ！</span>
            </template>
            <el-icon class="el-icon--right"><arrow-down /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="login">
                <el-icon> <InfoFilled /> </el-icon>前往登录
              </el-dropdown-item>
              <el-dropdown-item @click="logout">
                <el-icon> <SwitchButton /> </el-icon>注销用户
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "HeaderPage",
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
    },
  },
  methods: {
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
      this.$store.commit("NavigationModule", routeName);
      this.$store.commit("defaultEditTbale");
    },
    login() {
      //跳转到登录路由
      this.$router.push({ path: "/login" });
    },
    logout() {
      //清理数据
      this.$store.commit("resetState");
      //跳转到登录路由
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.video-link {
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中（可选） */
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin-right: 30px;
}

.el-row {
  align-items: center;
  height: 100%;
}

.el-col {
  display: flex;
  align-items: center;
}

.left-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.right-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.centerTitleMenu {
  display: flex;
  flex: 1;
  justify-content: center;
}

.centerTitleMenu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center; /* 确保子元素垂直居中 */
}

.centerTitleMenu li {
  position: relative;
  list-style: none;
  padding: 10px;
  margin-right: 50px;
  transition: all 0.2s linear;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.centerTitleMenu li::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  /* 初始位置在中间的左边 */
  width: 0;
  /* 初始宽度为0 */
  height: 3px;
  border-bottom: 3px solid #3b3b3b;
  transition: left 0.3s linear, width 0.3s linear;
}

.centerTitleMenu li:hover::before {
  left: 25%;
  /* hover时保持在中间的左边 */
  width: 50%;
  /* hover时宽度变为50% */
  border-bottom-color: #409eff;
}

.centerTitleMenu li:active {
  background: #2f4f4f;
  color: #fff;
}

.centerTitleMenu li.selected {
  color: blue;
  /* 选中时字体变为蓝色 */
}

:deep(.el-tooltip__trigger:focus-visible) {
  outline: unset;
}

header {
  background-color: #ecf0f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
