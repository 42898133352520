import { handleRequest, instance } from './commonutil';

const getCourses = async () => {
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/Course/GetCourseStats`);
    return await handleRequest(httpRequest, '课程加载中...');
};
const getCourseStudents = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/Course/GetCourseStudents?${queryString}`);
    return await handleRequest(httpRequest, '学员加载中...');
};
const postOpenStudentAutho = async (courseStudentId) => {
    const params = {
        courseStudentId: courseStudentId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/OpenStudentAutho?${queryString}`);
    return await handleRequest(httpRequest, '开通学员权限中...');
};
const postCloseStudentAutho = async (courseStudentId) => {
    const params = {
        courseStudentId: courseStudentId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/CloseStudentAutho?${queryString}`);
    return await handleRequest(httpRequest, '关闭学员权限中...');
};
const deleteStudent = async (courseStudentId) => {
    const params = {
        courseStudentId: courseStudentId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.delete(`/api/Course/DeleteStudent?${queryString}`);
    return await handleRequest(httpRequest, '删除学员中...');
};
const postTeacherAddCourseStudent = async (courseId, studentPhone) => {
    const params = {
        courseId: courseId,
        studentPhone: studentPhone
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/TeacherAddCourseStudent?${queryString}`);
    return await handleRequest(httpRequest, '老师添加学员中...');
};

const postReleaseCourseFullModel = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/ReleaseCourseFullModel?${queryString}`);
    return await handleRequest(httpRequest, '课程发布中...');
}
const postOffShelfCourseFullModel = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/OffShelfCourseFullModel?${queryString}`);
    return await handleRequest(httpRequest, '课程下架中...');
}
const deleteCourse = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.delete(`/api/Course/DeleteCourse?${queryString}`);
    return await handleRequest(httpRequest, '课程删除中...');
}
//管理员系列
const postAdminGetCourseFullModels = async () => {
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminGetCourseFullModels`);
    return await handleRequest(httpRequest, '获取所有课程中...');
}
const postAdminGetCourseFileFullModels = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminGetCourseFileFullModels?${queryString}`);
    return await handleRequest(httpRequest, '获取课程文件中...');
}
const postAdminSuccessAuditingCourseFullModel = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminSuccessAuditingCourseFullModel?${queryString}`);
    return await handleRequest(httpRequest, '审核通过课程中...');
}
const postAdminFailAuditingCourseFullModel = async (courseId) => {
    const params = {
        courseId: courseId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminFailAuditingCourseFullModel?${queryString}`);
    return await handleRequest(httpRequest, '审核驳回课程中...');
}
const postAdminSuccessCourseFile = async (courseFileId) => {
    const params = {
        courseFileId: courseFileId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminSuccessCourseFile?${queryString}`);
    return await handleRequest(httpRequest, '审核通过课程文件中...');
}
const postAdminFailCourseFile = async (courseFileId) => {
    const params = {
        courseFileId: courseFileId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/Course/AdminFailCourseFile?${queryString}`);
    return await handleRequest(httpRequest, '审核驳回课程文件中...');
}
const getInstallPackages = async () => {
    const httpRequest = () => instance.get(`/api/Soft/GetLastNewInstallPackageSoft`);
    return await handleRequest(httpRequest, '查询最新安装包中...');
}
export default {
    getCourses, getCourseStudents, postOpenStudentAutho, postCloseStudentAutho, deleteStudent,
    postTeacherAddCourseStudent, postReleaseCourseFullModel, postOffShelfCourseFullModel, deleteCourse,
    postAdminGetCourseFullModels, postAdminGetCourseFileFullModels, postAdminSuccessAuditingCourseFullModel, postAdminFailAuditingCourseFullModel,
    postAdminSuccessCourseFile, postAdminFailCourseFile, getInstallPackages
};