import { createRouter, createWebHistory } from 'vue-router'
import Index from "../Views/Index.vue";
import Login from "../Views/Login/login.vue";
import store from "../stores/store.js";
const routes = [
    {
        path: '/',
        redirect: '/Index'
    },
    {
        path: '/login',
        name: '登录页面',
        component: Login,
    },
    {
        path: '/Index',
        name: '主容器页面',
        component: Index,
        redirect: '/Index/home',
        children: [
            { path: '/Index/home', name: '默认首页', component: () => import('../components/Home.vue') },
            {
                path: '/Index/Cert',
                name: 'Cert',
                component: () => import('../Views/Container/AsideMenuPage.vue'),
                redirect: '/Index/Cert/certmanager',
                children: [
                    { path: '/Index/Cert/certmanager', name: '证书管理', component: () => import('../Views/CertServer/certManager.vue') },
                    { path: '/Index/Cert/monitormanager', name: '监控管理', component: () => import('../Views/CertServer/monitorManager.vue') },
                    { path: '/Index/Cert/nodeManager', name: '节点管理', component: () => import('../Views/CertServer/nodeManager.vue') },
                    //跳转页面
                    {
                        path: '/Index/Cert/certmanager/certupdaterecord', name: '证书更新记录',
                        component: () => import('../Views/CertServer/components/certrecord.vue')
                    },
                    {
                        path: '/Index/Cert/monitormanager/monitorupdaterecord', name: '监控更新记录',
                        component: () => import('../Views/CertServer/components/monitorrecord.vue')
                    },
                    {
                        path: '/Index/Cert/nodeManager/nodeRecord', name: '节点部署记录',
                        component: () => import('../Views/CertServer/components/nodeRecord.vue')
                    },
                ]
            },
            {
                path: '/Index/Course',
                name: 'Course',
                component: () => import('../Views/Container/AsideMenuPage.vue'),
                redirect: '/Index/Course/coursedownload',
                children: [
                    { path: '/Index/Course/coursedownload', name: '软件下载', component: () => import('../Views/CourseModule/courseDownload.vue') },
                    { path: '/Index/Course/teachermanager', name: '教师管理', component: () => import('../Views/CourseModule/teacherManager.vue') },
                    { path: '/Index/Course/adminmanager', name: '超管审核', component: () => import('../Views/CourseModule/adminManager.vue') },
                ]
            },
            {
                path: '/Index/System',
                name: 'System',
                component: () => import('../Views/Container/AsideMenuPage.vue'),
                redirect: '/Index/System/userpage',
                children: [
                    //角色权限配置
                    { path: '/Index/System/userpage', name: '用户配置', component: () => import('../Views/RolePermission/userPage.vue') },
                    { path: '/Index/System/rolepage', name: '角色配置', component: () => import('../Views/RolePermission/rolePage.vue') },
                    { path: '/Index/System/routepage', name: '路由配置', component: () => import('../Views/RolePermission/routePage.vue') },
                    //跳转页面
                    {
                        path: '/Index/System/userpage/userroleregion', name: '用户角色记录',
                        component: () => import('../Views/RolePermission/components/userRoleRegion.vue')
                    },
                    {
                        path: '/Index/System/rolepage/rolerouteregion', name: '角色路由记录',
                        component: () => import('../Views/RolePermission/components/roleRouteRegion.vue')
                    },
                ]
            },
            //个人中心
            {
                path: '/Index/usercenter', name: '个人中心', component: () => import('../Views/UserServer/userinfo.vue')
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    //判断路由是否已经加载过
    let isLoadRoute = store.state.isLoadRoute;
    if (!isLoadRoute) {
        // axios.get('/post/menuList').then(res => {
        //     store.commit('setMenuData', res.data.menu_data);
        //     //动态创建路由
        //     buildRouter();
        //     //设置已经加载过的标记
        //     store.commit("setLoadRoute", true);
        // });
    }
    // if (from.fullPath === '/') {
    //     store.commit("defaultEditTbale");
    // }
    next();
})
// const buildRouter = () => {
//     const data = store.state.menu_data;
//     data.forEach(item => {
//         const newRouter = {
//             path: item.path,
//             name: item.name,
//             component: () => import(`../Views/CertServer/${item.component}.vue`)
//         };
//         router.addRoute('index', newRouter);
//         // 动态添加到 'index' 路由的 children 中
//     });
//     const defaultRouter = {
//         path: '/index/default',
//         name: '产品总览',
//         component: () => import(`../components/Home.vue`)
//     };
//     router.addRoute('index', defaultRouter);
// };

export default router;
