import { createStore } from 'vuex';
import localstoragehelper from "../utils/localstoragehelper"
const state = {
    pageSizes: [5, 10, 20, 50, 100, 300, 500],
    isCollapse: true,
    token: localstoragehelper.getItem('token') || '',
    user: localstoragehelper.getItem('user') || {},
    project_menu_data: {
        Cert: [
            {
                name: '证书管理',
                icon: 'postcard',
            },
            {
                name: '监控管理',
                icon: 'View',
            },
            {
                name: '节点管理',
                icon: 'GobletSquareFull',
            }
        ],
        Course: [
            {
                name: '软件下载',
                icon: 'Download',
            },
            {
                name: '教师管理',
                icon: 'Film',
            },
            {
                name: '超管审核',
                icon: 'Sugar',
            }
        ],
        System: [
            {
                name: '用户配置',
                icon: 'User',
            },
            {
                name: '角色配置',
                icon: 'Female',
            },
            {
                name: '路由配置',
                icon: 'EditPen',
            }
        ],
        User: [

        ]
    },
    menu_data: [],
    isLoadRoute: true,
    editableTabsValue: '',
    editableTabs: []
};
const mutations = {
    NavigationModule(state, data) {
        state.menu_data = state.project_menu_data[data] || [];
        state.editableTabsValue = '',
            state.editableTabs = [];
    },
    setMenuData(state, data) {
        state.menu_data = data
    },
    setLoadRoute(state, data) {
        state.isLoadRoute = data
    },
    addEditableTabs(state, tab) {
        //根据tab.name 查询 editableTabs 是否已经存在
        let idx = state.editableTabs.findIndex(item => item.name === tab.name);
        if (idx === -1) {//不存在则可以添加
            state.editableTabs.push({
                title: tab.name,
                name: tab.name
            })
        }
        state.editableTabsValue = tab.name;
    },
    defaultEditTbale(state) {
        var back = (state.menu_data && state.menu_data[0] && state.menu_data[0].name) || '';
        this.commit("addEditableTabs", { name: back });
    },
    SET_TOKEN(state, data) {
        state.token = data.userToken;
        state.user = data;
        localstoragehelper.setItem("token", data.userToken);
        localstoragehelper.setItem("user", state.user);
    },
    Set_User_Email(state, data) {
        state.user.userEmail = data;
        localstoragehelper.setItem("user", state.user);
    },
    Set_User_Avater(state, data) {
        state.user.userHeaderImg = data;
        localstoragehelper.setItem("user", state.user);
    },
    resetState(state) {
        state.token = '';
        state.user = {};
        localStorage.clear();
    },
    toggleCollapse(state) {
        state.isCollapse = !state.isCollapse;
    },

};

const getters = {
    getUserState(state) {
        let data;
        if (state.userState == 0) {
            data = '无效'
        } else {
            data = state.userState + '级'
        }
        return data;
    }
};
export default createStore({
    state,
    mutations,
    getters
});