<template>
  <div class="footer">
    <el-link type="primary" href="https://www.cnblogs.com/lingyanspace" target="_blank"
      >博客园博客</el-link
    >
    <el-divider direction="vertical"></el-divider>
    <el-link type="primary" href="https://blog.csdn.net/MoY_18888" target="_blank"
      >CSDN博客</el-link
    >
    <el-divider direction="vertical"></el-divider>
    <p style="margin-right: 10px">答疑QQ群：571927325</p>
    <el-divider direction="vertical"></el-divider>
    <p style="margin-right: 10px">微信：13028792031</p>
    <el-link type="primary" href="https://beian.miit.gov.cn" target="_blank">陇ICP备2024011221号</el-link>
    <el-divider direction="vertical"></el-divider>
    <el-link type="primary" href="https://beian.mps.gov.cn" target="_blank"
      >甘公网安备62102202000159号</el-link
    >
    <el-divider direction="vertical"></el-divider>
    <p>Copyright &copy;{{ lastYear }}-{{ currentYear }} 灵燕空间.版权所有</p>
  </div>
</template>
<script>
export default {
  name: "FooterPage",
  data() {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    return {
      currentYear,
      lastYear,
    };
  },
};
</script>

<style scope>
.footer {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 30px;
}
</style>
